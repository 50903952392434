@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 'bold';
	font-style: normal;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
